<script setup lang="ts">
import { creditSalesInvoice } from '@/api/salesInvoices';
import SayErrorResponse from '@/mixins/sayErrorResponse';
import Say from '@/utils/Say'
import {inject, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
const {t} = useI18n()

const emit = defineEmits(['salesInvoiceCredited', 'cancelForm', 'loading'])
const props = defineProps({
  invoice: {
    type: Object,
    required: true
  },
  job: {
    type: Object,
    required: true
  }
})

const form = ref(null as HTMLFormElement | null)
const formValid = ref(false)
const formRules = ref({
  confirmationCheck: [
    value => {
      if (value) return true
      return t('pleaseConfirm')
    },
  ],
})
const confirmationCheck = ref(false)

const dialogTitle = inject('dialogTitle', null)
onMounted(() => {
  dialogTitle.value = t('creditSalesInvoice')
})

const formSubmitted = ref(false)
const submit = () => {
  if (form.value.validate() && !formValid.value) {
    return
  }

  if (formSubmitted.value) {
    return
  }
  formSubmitted.value = true
  emit('loading', true)

  creditSalesInvoice(props.invoice.id)
    .then(response => {
      if (response.data.error) {
        SayErrorResponse(response);
        emit('loading', false)
      } else {
        Say('success', t('proFormaRejectedSuccessfully'));
        emit('salesInvoiceCredited')
      }
    })
    .catch(error => {
      SayErrorResponse(error);
      emit('loading', false)
    })
    .finally(() => {
      formSubmitted.value = false
    });
}
const cancel = () => {
  emit('cancelForm')
}
defineExpose({submit, cancel})
</script>

<template>
  <div>
    <v-form
      v-model="formValid"
      ref="form"
    >
      <template v-if="job.client.exact_online_account_id === null">
        <v-alert type="warning" class="mb-5" density="compact">
          {{ t('clientIsNotLinkedToAnExactOnlineAccount') }}
          <router-link
            :to="{ name: 'ClientsEdit', params: { id: job.client.id } }"
            class="text-white"
          >
            {{ t('clickHereToLinkClientToAnExactOnlineAccount') }}
          </router-link>
        </v-alert>
      </template>
      <template v-else-if="job.client.exact_online_account.email == null || job.client.exact_online_account.email == ''">
        <v-alert type="warning" class="mb-5" density="compact">
          {{ t('exactOnlineAccountHasNoEmailAddress', { name: job.client.exact_online_account.code }) }}
          {{ t('pleaseCompleteItInExactOnline') }}
          {{ t('whenCompleted') }},
          <router-link
            :to="{ name: 'ClientsList' }"
            class="text-white"
          >
            {{ t('goToThisPageToSynchronizeChanges') }}
          </router-link>
        </v-alert>
      </template>
      <template v-else>
        <p class="mb-5" v-if="invoice.id">
          {{ t('invoiceToCredit') }}:<br>
          <b>
            {{ invoice.number }}
          </b>
        </p>
        <p class="mb-5" v-if="invoice.id">
          {{ t('exactOnlineAccount') }}:<br>
          <b>
            {{ job.client.exact_online_account.code }} - {{ job.client.exact_online_account.name }}<br/>
            {{ job.client.exact_online_account.full_address }}
          </b>
        </p>
        <p class="mb-5" v-if="invoice.id">
          {{ t('creditNoteWillBeEmailedTo') }}:<br>
          <b>
              <template v-if="job.client.exact_online_account.email">
                {{ job.client.exact_online_account.email }}
              </template>
              <span v-else class="text-red">
                {{ t('noEmailAddressAvailable') }}
              </span>
            </b>
        </p>

        {{ t('confirmCreditSalesInvoice') }}

        <p class="mt-4">{{$t('toConfirm')}}:</p>

        <v-checkbox
          v-model="confirmationCheck"
          :label="t('creditSalesInvoiceLabel')"
          :rules="formRules.confirmationCheck"
          hide-details
        />
      </template>
    </v-form>
  </div>
</template>
