<script setup lang="ts">
import { convertProFormaToSalesInvoice } from '@/api/proFormas';
import SayErrorResponse from '@/mixins/sayErrorResponse';
import Say from '@/utils/Say'
import {inject, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import { jobFilesIndex } from '@/api/jobs'

const {t} = useI18n()

const emit = defineEmits(['convertedToSalesInvoice', 'cancelForm', 'loading'])

const props = defineProps({
  invoice: {
    type: Object,
    required: true
  },
  job: {
    type: Object,
    required: true
  }
})
const form = ref(null as HTMLFormElement | null)
const formValid = ref(false)
// const formRules = ref({
//   confirmationCheck: [
//     value => {
//       if (value) return true
//       return t('pleaseConfirm')
//     },
//   ],
// })
// const confirmationCheck = ref(false)
const jobFiles = ref([])
const clientReference = ref('')
const attachments = ref([])
const jobId = inject('jobId', 0)

const dialogTitle = inject('dialogTitle', null)
onMounted(() => {
  dialogTitle.value = t('convertProFormaToSalesInvoice')

  try {
    jobFilesIndex(jobId.value).then(response => {
      let files = response.data.uploadedFiles;
      files = files.filter(file => file.extension === 'pdf');
      // Loop through files and set a custom ID for the checkbox (if file has a workOrderId, make it wo-<workOrderId>, otherwise make it j-<fileId>)
      files.forEach(file => {
        file.custom_id = ( file.workOrderId ? `wo-` : `j-` ) + file.id;
      });

      jobFiles.value = files;
      formSubmitted.value = false
    })
  } catch (error) {
    SayErrorResponse(error);
  }

})

const formSubmitted = ref(true)
const submit = () => {
  if (formSubmitted.value) {
    return
  }
  formSubmitted.value = true
  emit('loading', true)

  convertProFormaToSalesInvoice(props.invoice.id, {
    clientReference: clientReference.value,
    attachments: attachments.value
  })
    .then(response => {
      if (response.data.error) {
        SayErrorResponse(response);
        emit('loading', false)
        formSubmitted.value = false
      } else {
        setTimeout(() => {
          Say('success', t('proFormaApprovedSuccessfully'));
          emit('approved')
          formSubmitted.value = false
        }, 2000)
      }
    })
    .catch(error => {
      SayErrorResponse(error);
      formSubmitted.value = false
      emit('loading', false)
    });
}
const cancel = () => {
  emit('cancelForm')
}
defineExpose({submit, cancel})
</script>

<template>
  <div>
    <v-form
      v-model="formValid"
      ref="form"
      class="approve-proforma-form overflow-auto"
    >
      <template v-if="job.client.exact_online_account_id === null">
        <v-alert type="warning" class="mb-5" density="compact">
          {{ t('clientIsNotLinkedToAnExactOnlineAccount') }}
          <router-link
            :to="{ name: 'ClientsEdit', params: { id: job.client.id } }"
            class="text-white"
          >
            {{ t('clickHereToLinkClientToAnExactOnlineAccount') }}
          </router-link>
        </v-alert>
      </template>
      <template v-else-if="job.client.exact_online_account.email == null || job.client.exact_online_account.email == ''">
        <v-alert type="warning" class="mb-5" density="compact">
          {{ t('exactOnlineAccountHasNoEmailAddress', { name: job.client.exact_online_account.code }) }}
          {{ t('pleaseCompleteItInExactOnline') }}
          {{ t('whenCompleted') }},
          <router-link
            :to="{ name: 'ClientsList' }"
            class="text-white"
          >
            {{ t('goToThisPageToSynchronizeChanges') }}
          </router-link>
        </v-alert>
      </template>
      <template v-else>
        <!-- <p class="mb-5" v-if="invoice.id">
          {{ t('period') }}:<br>
          <b>
            {{ formatDate(invoice.start_date) }}
            {{ t('upToAndIncludingAbbreviation') }}
            {{ formatDate(invoice.end_date) }}
          </b>
        </p> -->
        <p class="mb-5" v-if="invoice.id">
          {{ t('salesInvoiceBasedOnProForma') }}:<br>
          <b>
            {{ invoice.number }}
          </b>
        </p>
        <p class="mb-5" v-if="invoice.id">
          {{ t('exactOnlineAccount') }}:<br>
          <b>
            {{ job.client.exact_online_account.code }} - {{ job.client.exact_online_account.name }}<br/>
            {{ job.client.exact_online_account.full_address }}
          </b>
        </p>
        <p class="mb-5" v-if="invoice.id">
          {{ t('invoiceWillBeEmailedTo') }}:<br>
          <b>
            <template v-if="job.client.exact_online_account.email">
              {{ job.client.exact_online_account.email }}
            </template>
            <span v-else class="text-red">
              {{ t('noEmailAddressAvailable') }}
            </span>
          </b>
        </p>
        <p>
          {{ t('attachPdfFiles') }}:<br>
        </p>
        <div class="attachmentsWrapper">
          <v-checkbox
            :model-value="true"
            hide-details
            class="attach-files-checkbox"
            readonly
          >
          <template v-slot:label>
            {{ invoice.number }}.pdf
          </template>
          </v-checkbox>
          <template v-if="jobFiles.length > 0">
            <v-checkbox
              v-for="file in jobFiles"
              v-model="attachments"
              :value="file.custom_id"
              hide-details
              class="attach-files-checkbox cursor-pointer"
            >
              <template v-slot:label>
                <a :href="file.url" target="_blank" class="link">{{ file.name }}.{{ file.extension }}</a>
                <span v-if="file.workOrderId" class="ml-2 work-order-badge">{{ t('workOrder') }} {{ file.workOrderId }}</span>
              </template>
            </v-checkbox>
          </template>
          <template v-else>
            <p class="ml-3 mb-3"><i>{{ t('noAdditionalUploadedJobFilesAvailable') }}</i></p>
          </template>
        </div>
        <p class="mb-5">
          <v-text-field
            v-model="clientReference"
            hide-details
            :label="t('clientReference')"
            :placeholder="t('clientReferencePlaceholder')"
            variant="underlined"
            />
        </p>
        <p>
          {{ t('confirmConvertToSalesInvoice') }}
        </p>
      </template>
    </v-form>
  </div>
</template>

<style scoped lang="scss">
.link {
  color: rgb(30, 136, 229);
  text-decoration: underline;
}
:deep(.attach-files-checkbox label) {
  opacity: 1;
}
.attachmentsWrapper {
  margin: 6px 0 12px 0;
  border: 1px solid #e0e0e0;
  max-height: 145px;
  overflow-y: auto;
}

.work-order-badge {
  background-color: #F57828 !important;
  color: #FFFFFF !important;
  border-radius: 3px;
  padding:0 5px;
  text-transform: uppercase;
}
</style>
