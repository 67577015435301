export default {
  dashboard: 'Dashboard',
  calenderView: 'Calender',
  mapView: 'Map',
  searchView: 'Search',
  mapOnlyShowsProjectLocations: 'This map only shows project locations (no Van Amerongen or rental supplier locations)',
  // Projects
  project: 'Project | Projects',
  projectNumber: 'Project number | Project numbers',
  projectCreate: 'Create project',
  projectEdit: 'Edit project',
  projectLead: 'Project lead',
  projectCreateSuccess: 'The project was created.',
  projectEditSuccess: 'The project was edited.',
  projectEditError: 'The project cannot be opened for editing. Try again later.',
  projectDeleteConfirm: 'Are you sure you want to delete this project?',
  projectDeleteSuccess: 'The project has been deleted.',
  projectWarningRentalStart: 'You need to set a rental start or end date on every row.',
  equipmentDuration: 'Equipment duration',
  unknownLocation: 'Unknown location',
  arrived: 'Arrived',
  departed: 'Departed',
  ongoing: 'Ongoing',
  finished: 'Finished',
  retrievedBeforeArriving: 'Retrieved before arriving',
  clientRef: 'Work nr.',
  contact: 'Contact | Contacts',

  // Work Orders
  workOrder: 'Work order | Work orders',
  workOrderCreate: 'Create work order',
  workOrderEdit: 'Edit work order',
  assignedEmployees: 'Assigned Employees',
  fileRemoveConfirm: 'Are you sure you want to remove this file? This is irreversible.',
  fileRemoveSuccess: 'The file has been deleted.',
  fileCommentsSuccess: 'The comments have been saved.',
  workOrderResetConfirm: 'Are you sure you want to reset this work order?',
  workOrderCreateSuccess: 'The work order has been created and can now be edited',
  workOrderEditSuccess: 'The work order has been edited',
  workOrderEditError: 'The work order cannot be opened for editing. Try again later.',
  workOrderDeleteConfirm: 'Are you sure you want to delete this work order?',
  workOrderDeleteSuccess: 'The work order has been deleted',
  workOrderDuplicateSuccess: 'The work order has been duplicated',
  workOrderApprovedSuccess: 'The work order has been approved',
  workOrderLinkedToOtherJobSuccess: 'The work order has been linked to another project',
  workOrderUpdatedTotalHoursSuccess: 'The total hours have been updated',
  workOrderStatuses: {
    DRAFT: 'Draft',
    PLANNED: 'Planned',
    ONGOING: 'Ongoing',
    COMPLETED: 'Completed',
    CLOSED: 'Closed',
    CANCELLED: 'Cancelled',
    APPROVED: 'Approved',
  },
  workOrderStatusesOngoing: {
    STARTED: 'Started',
    DEPARTED: 'Departed',
    ARRIVED: 'Arrived',
    RETURNING: 'Returning',
    RETURNED: 'Returned'
  },
  workOrderSelectEmployee: 'Please select an employee',
  projectName: 'Project name',
  jobName: 'Project',
  clientReference: 'Client reference',
  useOldInvoicingMethod: 'Use old invoicing method.',
  jobUsesOldInvoicingMethod: 'This project uses the old invoicing method.',
  revisions: 'Revisions',
  noRevisions: 'No revisions yet',
  noFilesUploaded: 'No files uploaded yet',
  noAdditionalUploadedJobFilesAvailable: 'No additional uploaded project files available.',
  addComment: 'Add a comment',
  clientResponsible: 'Executor',
  locationsVa: 'Van Amerongen location',
  relatedWorkOrders: 'Related work orders',
  newEmployeeHoursEntry: 'Employee hours entry',
  workOrderId: 'Work order id',
  workOrderForEquipmentMovementBetweenBranchesAndRentalSuppliers: 'WorkOrder for equipment movement between branches and rental suppliers',
  approveWorkOrder: 'Approve work order',
  approveWorkOrderText: 'The hours and moved equipment (including rental periods) are correct.',
  approveWorkOrderEquipmentMovementsText: 'The moved equipment is correct.',
  workOrderApproved: 'The work order has been approved.',
  canceledWorkOrderCanNotBeApproved: 'A canceled work order cannot be approved.',
  correctEquipmentOfWorkOrderForEquipmentMovements: 'To correct the equipment of the work order for equipment movements, go to `Inventory` in the main menu to move equipment between locations and rental suppliers.',
  viewAllEquipmentOfThisJob: 'View all equipment of this project',
  inTheEquipmentTabOfTheJob: 'in the equipment tab of the project',
  viewAllWorkordersOfThisJob: 'View all work orders of this project',
  inTheWorkordersTabOfTheJob: 'in the workorder tab of the project',
  totalAmountRegistered: 'Total amount registered',
  hoursForInvoice: 'Hours for invoicing',
  notYetFilledIn: 'Not yet filled in',
  fillInTotalHoursBeforeApproving: 'Please fill in the total hours for invoicing before approving.',
  noHoursWillBeChargedForThisWorkOrder: 'No hours will be charged for this work order',

  // Jobs
  job: 'Project | Projects',
  jobResult: 'Project\'s Work Order | Project\'s Work Orders',
  jobCreate: 'Create project',
  jobCreated: 'New project created',
  jobAfterCreate: 'Do you want to create a work order for this project?',
  jobEdit: 'Edit project',
  jobResetConfirm: 'Are you sure you want to reset this project?',
  jobCreateSuccess: 'The project has been created and can now be edited',
  jobEditSuccess: 'The project has been edited',
  jobDeleteConfirm: 'Are you sure you want to delete this project?',
  jobDeleteSuccess: 'The project has been deleted',
  jobDuplicateSuccess: 'The project has been duplicated',
  jobStatuses: {
    DRAFT: 'Draft',
    PLANNED: 'Planned',
    ONGOING: 'Ongoing',
    COMPLETED: 'Completed',
    CLOSED: 'Closed',
    CANCELLED: 'Cancelled'
  },
  jobStatusesOngoing: {
    STARTED: 'Started',
    DEPARTED: 'Departed',
    ARRIVED: 'Arrived',
    RETURNING: 'Returning',
    RETURNED: 'Returned'
  },
  jobSelectEmployee: 'Please select an employee',
  linkToOtherJob: 'Link to other project',
  jobsWithStatusPlannedAndOngoingAndSameClientAndBranch: 'Projects with status `Planned` and `Ongoing` from the same client and the same branch',
  noJobsFound: 'No projects found',

  // Tabs
  JobTabOverview: 'Overview',
  JobTabWorkorders: 'Workorders',
  JobTabEquipment: 'Equipment',
  JobTabMap: 'Map',
  JobTabDocuments: 'Documents',
  JobTabNotes: 'Notes',
  JobTabInvoices: 'Invoices',
  JobTabCostOverview: 'Cost overview',

  // Branches
  branch: 'Branch | Branches',
  branchCreate: 'New branch',
  branchCreateSuccess: 'The branch was created',
  branchEdit: 'Edit branch',
  branchEditSuccess: 'The branch was saved',
  branchDeleteConfirm: 'Are you sure you want to delete this branch?',
  branchDeleteSuccess: 'The branch has been deleted',

  // Clients
  client: 'Client | Clients',
  clientCreate: 'Create client',
  clientEdit: 'Edit client',
  clientEditSuccess: 'The client was saved',
  clientArchiveConfirm: 'Are you sure you want to archive this client?',
  clientArchiveSuccess: 'The client has been archived',
  clientContactDeleteConfirm: 'Are you sure you want to delete this contact?',
  clientContactDeleteSuccess: 'The contact has been deleted',
  clientsArchived: 'Archived Clients',
  restoreClientConfirm: 'Are you sure you want to restore this client?',
  restoreClientSuccess: 'The client has been restored.',
  exactOnlineAccount: 'Exact Online account',
  syncExactOnlineAccounts: 'Sync Exact Online accounts',
  exactOnlineAccountsSynced: 'Exact Online accounts have been synced',
  clientIsNotLinkedToAnExactOnlineAccount: 'This client is not linked to an Exact Online account.',
  clickHereToLinkClientToAnExactOnlineAccount: 'Click here to link this client to an Exact Online account',
  exactOnlineAccountHasNoEmailAddress: 'The Exact Online account \'{name}\' has no email address.',
  whenCompleted: 'When completed',
  goToThisPageToSynchronizeChanges: 'go to this page to synchronize changes',

  // Employees
  // To discuss workflow in this page, perhaps these will either be removed or have other items added to it
  employee: 'Employee | Employees',
  employeeArchived: 'Archived Employee | Archived Employees',
  employeeCreate: 'Create employee',
  employeeCreateSuccess: 'The employee was created',
  employeeEdit: 'Edit employee',
  employeeEditSuccess: 'The employee was saved',
  personalDetails: 'Personal Details',
  employmentDetails: 'Employment Details',
  userDetails: 'User Details',
  functionDescription: 'Function Description',
  defaultWorkRole: 'Default Work Role',
  ownEmployee: 'Van Amerongen employee',
  employmentStart: 'Employment start',
  employmentEnd: 'Employment end',
  isPreviousEmployee: 'The employee is no longer with this company',
  employeeDeleteConfirm: 'Are you sure you want to delete this employee?',
  employeeDeleteSuccess: 'The employee has been deleted.',
  contractType: 'Contract Type',
  contractEnd: 'Contract End',
  weekOverview: 'Week Overview',
  dayWeekStat: 'Day week stat',
  employeeTimeoffDeleteConfirm: 'Are you sure you want to delete this?',
  employeeSelect: 'Please select an employee',
  employeeCanBeProjectLeader: 'Can be project leader',
  employeeCanBeWorkOrderResponsible: 'Can be work order responsible',

  deleteEmployeeConfirm: 'Are you sure you want to archive this employee?',
  restoreEmployeeConfirm: 'Are you sure you want to restore this employee?',
  deleteEmployeeSuccess: 'The employee has been archived.',
  restoreEmployeeSuccess: 'The employee has been restored.',

  // Equipment
  equipment: 'Equipment | Equipment',
  equipmentAll: 'All Equipment',
  equipmentUnique: 'Unique Equipment',
  equipmentBulk: 'Bulk Equipment',
  equipmentSuggested: 'Suggested Equipment',
  equipmentMoved: 'Equipment moved',
  equipmentChoose: 'Please choose a piece of equipment',
  equipmentCreate: 'Create Equipment',
  equipmentDeleteConfirm: 'Are you sure you want to delete this?',
  equipmentDeleteSuccess: 'The equipment has been deleted.',
  equipmentEditSuccess: 'The equipment has been edited.',
  equipmentCreateSuccess: 'The equipment has been created.',
  equipmentImageWarning: 'Warning, the previous image will be overwritten.',
  equipmentKeepTrackOfEquipmentItem: 'Keep track of equipmentItem',
  equipmentParent: 'Base item',
  report: 'Report | Reports',
  noEquipment: 'No material',
  Material: 'Material',
  delivered: 'Delivered',
  retrieved: 'Retrieved',
  equipmentRateType : 'Weekly | 123W | Once',
  fromLocation: 'From location',
  fromLocationRequired: 'From location is required',

  // Composite Equipment
  equipmentCompositeShort: 'Samengesteld',
  equipmentComposite: 'Composite Equipment | Composite Equipment',
  equipmentCompositeAll: 'Composite Equipment',
  equipmentCompositeWith: 'Parts',

  // Users
  user: 'User | Users',
  userCreate: 'Create user',
  userEdit: 'Edit user',
  userSelect: 'Select user',
  userCreateSuccess: 'The user has been created and can now be edited',
  userEditSuccess: 'The user was saved',
  userDeleteConfirm: 'Are you sure you want to delete this user?',
  userDeleteSuccess: 'The user has been deleted.',
  associatedUser: 'Associated user',
  passwordHint: 'Leave field empty to keep the same password',
  pincodeHint: 'Leave field empty to keep the same pincode',

  // Equipment Card
  equipmentCardWarningZeroAmount: 'This row will be deleted if you save with an amount of zero.',
  equipmentCardWarningRentalStart: 'You need to set a rental start or end date.',
  equipmentCardNoEquipment: 'There is no equipment to display.',
  equipmentCardSelect: 'Select equipment',
  equipmentCardSetDateAndTime: 'Set date and time',
  startDateMustBeBeforeEndDate: 'Start date must be before end date',
  endDateMustBeAfterStartDate: 'End date must be after start date',
  equipmentCardWarningZeroAmounts: 'There are rows with an amount of zero. These will be deleted if you save. Adjust the amounts or remove the row(s).',
  equipmentCardSuccess: 'The equipment was saved',

  // Roles
  role: 'Role | Roles',
  roleManage: 'Manage roles',
  rolePermissionsSaved: 'Role permissions saved',

  // Work Roles
  workRole: 'Work role | Work roles',

  // Reports
  totalHours: 'Total hours',
  subtotalHours: 'Subtotal hours',
  workedHours: 'Worked hours',
  leaveAndHolidays: 'Leave and holidays',
  summary: 'Summary',
  piketHours: 'Piket hours',

  // EquipmentItem
  allEquipmentItems: 'All EquipmentItems',
  equipmentItem: 'EquipmentItem | EquipmentItems',
  equipmentItemCreate: 'Create EquipmentItem',
  equipmentItemDeleteConfirm: 'Are you sure you want to delete this equipmentItem?',
  equipmentItemEdit: 'Edit EquipmentItem',
  equipmentItemCreateSuccess: 'EquipmentItem created',
  equipmentItemEditSuccess: 'EquipmentItem saved | Equipment Items saved',
  equipmentItemView: 'View EquipmentItem',
  locations: 'Locations',
  currentLocation: 'Current location',
  since: 'since',
  uniqueFeature: 'Unique feature',
  dateAcquired: 'Date acquired',
  statusDate: 'Status date',
  updateStatus: 'Update status',
  AtLocation: 'At location',
  ownEquipment: 'Own equipment',
  rentedEquipment: 'External rented equipment',
  rentedFrom: 'Rented from',
  createdBySystem: 'Created by system',
  printOnlyPossibleForGroupedViewWithLocation: 'Printing is only possible for grouped view with location',
  itemsAreOnlyClickableInViewWithIndividualItems: 'Items are only clickable in view with individual items',
  wasEquipmentAtLocation: 'Equipment at location',
  isAttachedToJob: 'Attached to project',

  // Inventory Item Locations
  allEquipmentItemLocations: 'All Inventory Item Locations',
  equipmentItemLocation: 'Inventory Item Location | Inventory Item Locations',
  equipmentItemLocationCreate: 'Create Inventory Item Location',
  equipmentItemLocationDeleteConfirm: 'Are you sure you want to delete this inventory item location?',
  equipmentItemLocationEdit: 'Edit Inventory Item Location',
  equipmentItemLocationCreateSuccess: 'The inventory item location was created',
  equipmentItemLocationEditSuccess: 'The inventory item location was saved',
  addEquipmentItemLocation: 'Add location',
  moveToOtherLocation: 'Move to other location',
  vaLocation: 'Van Amerongen branch',
  placedAt: 'Placed at',
  placedByWorkOrder: 'Placed by work order',
  collectedAt: 'Collected at',
  collectedByWorkOrder: 'Collected by work order',
  rentalSuppliersLocation: 'Rental supplier location',
  clientLocation: 'Client location',
  compositeWith: 'Composite with',
  compositeAs: 'Composite as',
  isRentalSupplied: 'Rental supplied',
  itemsAlreadyAtLocation: '{count} item(s) already at the new location.',
  itemsAlreadyAtLocationAll: 'All items already at selected location.',
  itemsAlreadyAtLocationSingle: 'The item is already at selected location.',
  itemsAlreadyAtLocationMove: 'Move remaining items.',
  inventory: 'Inventory',
  pickupFromLocation: 'Pickup from location',
  bringToLocation: 'Bring to location',

  // Rental Suppliers
  rentalSuppliers: 'Rental suppliers',
  allRentalSuppliers: 'All rental suppliers',
  rentalSupplierCreate: 'Create rental supplier',
  rentalSupplier: 'Rental supplier | Rental suppliers',
  rentalSupplierEdit: 'Edit rental supplier',
  rentalSupplierEditSuccess: 'The rental supplier was saved',
  rentalSupplierCreateSuccess: 'The rental supplier was created',
  rentalSupplierDeleteConfirm: 'Are you sure you want to delete this rental supplier?',
  rentalSupplierBrannch: 'Rental supplier branch',

  // Notes
  notes: 'Notes',

  // Search
  searchResultsNoResults: 'No results found. Please search in the left sidebar for something else.',

  // Days of week:
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',

  //UI Document
  image: 'Image | Images',
  document: 'Document | Documents',
  documentUpload: 'Upload Images/Documents',
  documentDeleteConfirmation: 'Are you sure you want to delete this document? | Are you sure you want to delete these documents?',
  documentDeletedSuccess: 'The document has been deleted. | The documents has been deleted.',

  //Job -> Notes
  pleaseFillInTitle: 'Please fill in the title',
  pleaseFillInContent: 'Please fill in the content',
  addNote: 'Add note',

  jobNoteCreateSuccess: 'The note was created.',
  jobNoteEditSuccess: 'The note was edited.',
  jobNoteDeleteConfirmation: 'Are you sure you want to delete this note?',
  jobNoteDeletedSuccess: 'The note has been deleted.',

  JobTabAdditionalCosts: 'Additional costs',
  dialogAddCost: 'Create cost',
  dialogEditCost: 'Edit cost',
  costCreated: 'The cost was created',
  costUpdated: 'The cost was edited',
  costDeleted: 'The cost has been deleted deleted',
  pricePerUnitExclVat: 'Price per unit excl. VAT',
  amountExclVat: 'Amount excl. VAT',
  additionalCostCannotBeEdited: 'This additional cost cannot be edited because it is part of a pro forma.',
  additionalCostCannotBeDeleted: 'This additional cost cannot be deleted because it is part of a pro forma.',
  addAdditionalCost: 'Add additional cost',

  // Cost Overview
  costOverviewDescription: 'In this cost overview, equipment with a rental period (at least rental start) and - if applicable - the associated lettering costs, hours of approved work orders and additional costs are included.',
  conceptCheckoutPeriod: 'Concept checkout period',
  noDataAvailable: 'No data available',
  proposedProFormaLines: 'Proposed pro forma lines',
  total: 'Total',
  lettering: 'Lettering',
  letteringInternalArticleNumber: 'Lettering - Internal article number',
  additionalCosts: 'Additional costs',
  dateOrPeriod: 'Date / Period',
  createCheckoutPeriod: 'Create checkout period',
  updateStartDate: 'Update start date',
  updateEndDate: 'Update end date',
  today: 'today',
  upToAndIncludingAbbreviation: 'up to and including',
  removeCheckoutPeriod: 'Remove checkout period',
  removeCheckoutPeriodExplanation: 'This will only remove the start and end date of the checkout period. The associated costs of material rental, lettering, hours and additional costs will be retained.',
  endDateUpdatedSuccessfully: 'The end date has been updated successfully.',
  startDateUpdatedSuccessfully: 'The start date has been updated successfully.',
  checkoutPeriodRemovedSuccessfully: 'The checkout period has been removed successfully.',
  overviewIsBeingLoaded: 'Overview is being loaded',
  areYouSureYouWantToRemoveTheCheckoutPeriod: 'Are you sure you want to remove the checkout period?',
  tooltipStartDateOfCheckoutPeriodHasCosts: 'The start date of this checkout period has costs.',
  tooltipFollowingCheckoutAndProForma: 'This checkout period has a following checkout period and has one or more pro formas.',
  tooltipFollowingCheckoutPeriod: 'This checkout period has a following checkout period.',
  tooltipProForma: 'This checkout period has one or more pro formas.',
  tooltipFirstDatesAreEqual: 'The start date of the first checkout period and the first date with costs are equal.',
  tooltipFirstDatesAreEqualAndProForma: 'The start date of the first checkout period and the first date with costs are equal and this checkout period has one or more pro formas.',
  tooltipStartAndEndDateCannotBeInTheFuture: 'The start and end date cannot be today or in the future.',
  firstDatesAreNotEqual: 'The start date of the first checkout period and the first date with costs are not equal',
  lastDatesAreNotEqual: 'The end date of the last checkout period is not equal to the last date with costs',
  workOrderInProFormaPeriod: 'This Work order falls within a checkout period with a pro forma and cannot be edited.',
  pleaseEnterAValidEndDate: 'Please enter a valid end date',
  pleaseEnterAValidStartDate: 'Please enter a valid start date',

  // Invoices
  proForma: 'Pro forma',
  proFormas: 'Pro formas',
  createProforma: 'Create pro forma',
  rejectProforma: 'Reject pro forma ',
  approveProforma: 'Approve pro forma',
  fromDate: 'From date',
  upToAndIncludingDate: 'Up to and including date',
  proFormaRejectionLabel: 'Pro forma rejection',
  proFormaApprovalLabel: 'Approve pro forma',
  proFormaCompleteAllFields: 'Complete all field.',
  proFormaCreated: 'The pro forma was created',
  proFormaEdited: 'The pro forma was edited',
  proFormaDeleted: 'The pro forma was deleted',
  invoiceDate: 'Invoice date',
  invoiceTotalExcl: 'Total excl. VAT',
  checkingForConceptCheckoutPeriods: 'Checking if there are concept checkout periods',
  conceptCheckoutPeriodInsctructions: 'This concept checkout period can be converted into a checkout period in the cost overview',
  futureConceptCheckoutPeriodInsctructions: 'This concept checkout period is today and/or in the future and cannot be converted into a checkout period yet',
  noCheckoutPeriodsFound: 'No (concept) checkout periods found',
  proFormaRejectedSuccessfully: 'The pro forma has been rejected',
  proFormaApprovedSuccessfully: 'The pro forma has been approved',
  yourReference: 'Your reference',
  jobDescription: 'Job description',
  vat: 'VAT',
  discountPercentage: 'Discount percentage',
  discount: 'Discount',
  totalExclVat: 'Total excl. VAT',
  totalBeforeDiscount: 'Total before discount',
  discountOn: 'discount on',
  deleteProForma: 'Delete pro forma',
  confirmDeleteProForma: 'Are you sure you want to delete this pro forma? This is irreversible.',
  deletedProFormaSuccesfully: 'The pro forma has been deleted',
  finalizeProForma: 'Finalize and send',
  finalize: 'Finalize',
  confirmFinalizeProForma: 'Are you sure you want to make this pro forma final?',
  proFormaWillBeEmailedTo: 'The pro forma will be emailed to',
  clientResponsibleNotSetOrEmailMissing: 'There is no contact person filled in for the client, or it is missing an email address. This can be added by editing the project.',
  editJob: 'Edit job',
  finalizedProFormaSuccesfully: 'The pro forma has been made final',
  openPreview: 'Open preview',
  backToOverview: 'Back to overview',
  updateProFormaLine: 'Update line',
  createProFormaLine: 'Add line',
  deleteProFormaLine: 'Remove line',
  confirmDeleteProFormaLine: 'Are you sure you want to remove this line? This is irreversible.',
  confirmConvertToSalesInvoice: 'Are you sure you want to convert this pro forma to a sales invoice? A sales invoice will be created in Exact Online and sent by email to the client.',
  confirmApproveProForma: 'Are you sure you want to approve this pro forma?',
  confirmRejectProForma: 'Are you sure you want to reject this pro forma? A copy of the pro forma will be saved as a concept.',
  createdProFormaLineSuccessfully: 'The line has been added',
  updatedProFormaLineSuccessfully: 'The line has been updated',
  deletedProFormaLineSuccessfully: 'The line has been removed',
  attachPdfFiles: 'Attach PDF files',
  clientReferencePlaceholder: 'E.g. Purchase order number: 12345',
  addressedTo: 'Addressed to',
  invoiceWillBeEmailedTo: 'The invoice will be emailed to',
  creditNoteWillBeEmailedTo: 'The credit note will be emailed to',
  toCredit: 'Credit',
  toInvoice: 'Invoice',
  creditSalesInvoice: 'Credit sales invoice',
  confirmCreditSalesInvoice: 'Are you sure you want to credit this sales invoice? This will credit all lines and the total amount of the sales invoice. The credit note will be created in Exact Online and sent by email to the client.',
  creditSalesInvoiceLabel: 'Credit sales invoice',
  convertProFormaToSalesInvoice: 'Convert pro forma to sales invoice',
  convertToSalesInvoice: 'Convert to sales invoice',
  proFormaNumber: 'Pro forma number',
  salesInvoiceBasedOnProForma: 'Sales invoice based on pro forma',
  createSalesInvoice: 'Create sales invoice',
  invoiceToCredit: 'Invoice to credit',

  // ProForma statuses
  proFormaStatuses: {
    CONCEPT: 'CONCEPT',
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    INVOICED: 'INVOICED',
    null: '',
  },

  // Email statuses
  emailStatuses: {
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    SENT: 'SENT',
    PENDING: 'PENDING',
    FAILED: 'FAILED',
    PARTIALLY_FAILED: 'PARTIALLY FAILED',
    DELIVERED: 'DELIVERED',
    OPENED: 'OPENED',
    CLICKED: 'CLICKED',
    COMPLAINED: 'SPAM COMPLAINT',
    null: '',
  },

  // Reports
  reports: 'Reports',
  workInProgress: 'Work in progress',
  lastDateIncluded: 'Last date included',
  workInProgressReport: 'Work in progress reports',
  workInProgressReports: 'Work in progress reports',
  failureMessage: 'Failure message',
  viewWorkInProgressReport: 'View work in progress report',
  viewReport: 'View report',

  // @TODO: remove
  loadReportOfWorkInProgress: 'Load insights',
  workInProgressIsBeingLoaded: 'Insights are being loaded... Please wait',
  todayAndOrFuture: 'today and/or future',

  // Common words
  id: 'Id',
  hours: 'Hours',
  hourlyRateExclVat: 'Hourly rate excl. VAT',
  toConfirm: 'To confirm',
  pleaseConfirm: 'Confirmation required',
  title: 'Title',
  content: 'Content',
  cancel: 'Cancel',
  ok: 'Ok',
  set: 'Set',
  reset: 'Reset',
  create: 'Create',
  save: 'Save',
  edit: 'Edit',
  delete: 'Delete',
  archive: 'Archive',
  restore: 'Restore',
  newTab: 'New tab',
  see: 'View',
  showMore: 'Show more',
  manage: 'Manage',
  search: 'Search',
  areaInMeters: 'Area (m²)',
  name: 'Name | Names',
  username: 'Username',
  email: 'Email | Emails',
  emailAddress: 'Email address',
  password: 'Password',
  pincode: 'Pincode',
  phone: 'Phone',
  date: 'Date',
  time: 'Time',
  start: 'Start',
  dateStart: 'Date Start',
  timeStart: 'Time Start',
  timePause: 'Time Pause',
  end: 'End',
  dateEnd: 'Date End',
  timeEnd: 'Time End',
  dateAndTime: 'Date and time',
  description: 'Description',
  company: 'Company',
  leadBy: 'Lead By',
  locationFrom: 'Location From',
  locationFromHint: 'Leave empty for Van Amerongen HQ',
  locationDefault: 'Default Location',
  locationCreate: 'Create Location',
  inTransitFrom: 'In transit from',
  atLocation: 'At Location',
  noLocations: 'No locations',
  noEquipmentAtLocation: 'No equipment at location',
  address: 'Address',
  intersection: 'Intersection',
  custom: 'Custom',
  responsible: 'Responsible',
  settings: 'Settings',
  logIn: 'Log In',
  logOut: 'Log Out',
  filename: 'Filename',
  size: 'Size',
  noFiles: 'No files yet!',
  permission: 'Permission | Permissions',
  openGoogleMaps: 'Open in Google Maps',
  onlyAvailableUsers: 'Only available users',
  comment: 'Comment | Comments',
  amount: 'Amount',
  quantity: 'Quantity',
  add: 'Add',
  remove: 'Remove',
  year: 'Year',
  week: 'Week',
  hour: 'Hour | Hours',
  pause: 'Pause',
  general: 'General',
  reason: 'Reason',
  category: 'Category',
  bulk: 'Bulk',
  type: 'Type',
  rowsPerPage: 'Rows per page',
  all: 'All',
  allResults: 'To all results',
  or: 'Or',
  street: 'Street',
  streetNumber: 'Street number',
  postcode: 'Postcode',
  city: 'City',
  costCenter: 'Cost Center',
  function: 'Function',
  instructions: 'Instructions',
  rate: 'Rate',
  rateType: 'Rate Type',
  letteringCost: 'Lettering Cost',
  meta: 'Meta',
  status: 'Status',
  statusSince: 'Status since',
  startedAt: 'Started at',
  failedAt: 'Failed at',
  actions: 'Actions',
  from: 'From',
  to: 'To',
  to2: 'To',
  nothing: 'Nothing',
  details: 'Details',
  location: 'Location | Locations',
  loaded: 'Loaded',
  file: 'File | Files',
  duration: 'Duration',
  extraWorkers: 'Extra workers',
  workers: 'Workers',
  hoursWorked: 'Hours Worked',
  confirm: 'Are you sure?',
  lastUpdated: 'Last updated',
  noItems: 'No items',
  noDate: 'No date',
  at: 'At',
  on: 'On',
  by: 'By',
  deleted: 'Deleted',
  alphabetic: 'Alphabetic',
  changed: 'Changed',
  changedRecently: 'Changed recently',
  rentalStart: 'Rental start',
  rentalEnd: 'Rental end',
  print: 'Print',
  now: 'Now',
  confirmDeleteItem: 'Do you really want to delete this entry?',
  cantUndo: 'This action cannot be undone.',
  yes: 'Yes',
  no: 'No',
  unknown: 'Unknown',
  duplicate: 'Duplicate',
  duplicatingNotPossibleBecauseOfLocationWithoutCoordinates: 'Duplicating is not possible because the location does not have coordinates',
  duplicatingNotPossibleBecauseOfCategory: 'Duplicating is not possible because the category `Diverse` is not available',
  useTabToContinue: 'Use the "TAB" key to go to the next field',
  info: 'Info',
  locationDataThatWillBeSaved: 'The following location data will be saved:',
  additionalExplanation: 'Additional explanation',
  addLocation: 'Add location',
  latitude: 'Latitude',
  longitude: 'Longitude',
  streetOrRoad: 'Street (or road)',
  pleaseFillInAValidIntersection: 'Please fill in a valid intersection (two streets or roads seperated by a &-sign)',
  pleaseFillInCity: 'Please fill in the city',
  pleaseFillInLatitudeAndLongitude: 'Please fill in the latitude and longitude',
  pleaseFillInAStreet: 'Please fill in a street (or road)',
  pleaseFillInANameOrStreet: 'Please fill in a name or street (or road)',
  pleaseFillInName: 'Please fill in a name',
  similarExistingLocationsOfThisClient: 'Similar existing locations of this client:',
  notLinkedToWorkOrder: 'Not linked to any work order',
  clickOnExistingLocationToLinkItToWorkOrder: 'Click on an existing location to link it to the work order.',
  changeTypeToIntersection: 'Do you want to change the type to intersection?',
  createdAt: 'Created at',
  userId: 'User id',
  userName: 'Name of user',
  inTransitSince: 'In transit since',
  inTransitUntil: 'In transit until',
  createdBy: 'Created by',
  showAs: 'Show as',
  grouped: 'Grouped',
  individual: 'Individual',
  unique: 'Unique',
  composite: 'Composite',
  restrictionsBecauseEquipmentIsAlreadyInEquipmentItems: 'Operations are limited because the equipment has already been added to the inventory items or is part of a composite equipment.',
  currentVariantInDatabase: 'Current variant in database',
  consumable: 'Consumable',
  notApplicableAbbr: 'N/A',
  amountOfMonthsBack: 'Amount of months back',
  month: 'month',
  months: 'months',
  approve: 'Approve',
  reject: 'Reject',
  loading: 'Loading',
  endDateBeforeStartDate: 'The end date must be equal to or after start date',

  Equipment: 'Equipment',
  PlaceEquipment: 'Place Equipment',
  PlaceEquipmentAtLocation: 'Place at location',
  PlacedEquipmentAt: 'Placed on',
  CollectedEquipmentAt: 'Collected on',
  PlacedByWorkOrder: 'Placed by work order',
  CollectedByWorkOrder: 'Collected by work order',
  RentEquipmentStart: 'Rent start',
  RentEquipmentEnd: 'Rent end',
  RentalSupplierName: 'Rental supplier',
  SystemCreated: 'System created',
  CollectEquipment: 'Collect Equipment',
  CollectEquipmentSingle: 'Collect Equipment',
  CollectEquipmentGroup: 'Collect Equipment',
  Amount: 'Amount',
  leaveEmptyForToday: 'Leave empty for \'Today\'',
  dialogRentalStartAndEnd: 'Change rental start and end',
  RentalStartAndEnd: 'Rental',
  MarkAsCollected: 'Collect',
  dialogBulkRentalStart: 'Bulk change rental start',
  dialogBulkRentalEnd: 'Bulk change rental end',
  dialogBulk: 'Bulk change',
  warningMixedSupplierTypes: 'Equipment from multiple suppliers are selected. Only equipment appropriate to the selected location will be moved. All other equipment will remain at the current location.',
  warningSomeItemsAlreadyCollected: 'Some of the equipment was already collected. Only equipment not already collected with be moved. All other equipment will remain at the current location.',
  warningOnlyPartialRentalDateUpdatePossible: 'Some of the equipment cannot be set to the selected date.',
  warningAllItemsAlreadyCollected: 'All of the equipment was already collected.',
  warningMixedSupplierTypesMove: 'Move appropriate equipment',
  warningPartialUpdate: 'Set date for appropriate equipment.',
  validationErrorRentDate: 'The date is not valid for the selected items.',
  modify: 'Change',
  pleaseFillInMaterial: 'Please fill in the material',
  pleaseFillInLocation: 'Please fill in the location',
  pleaseFillInAmount: 'Please fill in the amount',
  pleaseFillInQuantity: 'Please fill in the quantity',
  pleaseFillInWorkOrder: 'Please fill in the work order',
  amountIsMoreThanAvailable: 'The quantity you entered is more than what is available',
  amountIsMoreThanAllowed: 'You can only place {max} items at a time',
  pleaseFillInRentalStart: 'You need to set a rental start date.',
  pleaseFillInRentalEnd: 'You need to set a rental end date.',
  warningStockWillBeAddedToLocation: 'Additional material will be added to inventory.\n',
  dialogMessagePlaced: 'Placed at location',
  filterByWorkOrder: 'Filter by work order',
  items: 'Items',
  close: 'Close',
  locationOrWorkOrder: 'Location / work order',
  overviewGeneratedAt: 'Overview generated at',
  checkoutPeriod: 'Checkout period',
  activities: 'Activities',
  fullPeriod: 'Full period',
  internalArticleNumber: 'Internal article code',
  period: 'Period',
  number: 'Number',
  emailStatus: 'Email status',
  sentTo: 'To',
  cc: 'CC',
  bcc: 'BCC',
  replyTo: 'Reply to',
  sentAt: 'Sent at',
  attachments: 'Attachment(s)',
  sentVia: 'Sent via',
  messageId: 'Message ID',
  noEmailsFound: 'No emails found.',
  seeDetailedStatusHistoryBelowEmail: 'See detailed status history below email',
  statusHistory: 'Status history',
  htmlVersion: 'HTML version',
  plainTextVersion: 'Plain text version',
  sendEmail: 'Send email',
  resendEmail: 'Resend email',
  sendEmailFor: 'Send email for',
  resendEmailFor: 'Resend email for',
  subject: 'Subject',
  send: 'Send',
  emailSent: 'Email sent',
  emailIsBeingSent: 'Email is being sent',
  resfreshData: 'Refresh data',
  getEventsFromMailgun: 'Get status from Mailgun (manual)',
  eventsUpdated: 'Status history updated',
  isUpdatingEvents: 'Status history is being retrieved and updated',
  eventsOnlyAvailableForMailgun: 'Status history is only available for emails sent via Mailgun.',
  notAllowed: 'Not allowed',
  noEmailAddressAvailable: 'No email addresss available',

  EquipmentSuppliedBy: 'Supplied by',
  OwnEquipment: 'VAF-Infra',

  SingleItems: 'Individual',
  GroupedItems: 'Grouped',

  allEquipmentAtLocation: 'All equipment at location',
  equipmentPartiallyCollected: 'Equipment partially collected',
  allEquipmentCollected: 'All equipment collected',

  // Validation
  validationNameRequired: 'Name is required',
  validationPostcodeRequired: 'Postcode is required',
  validationNameShorter: 'Name must be shorter',
  validationEmailRequired: 'E-mail is required',
  validationEmailValid: 'E-mail must be valid',
  validationPasswordRequired: 'Password is required',
  validationPasswordMinLength: 'Password must be at least 6 characters long',
  validationPincodeExactlyFour: 'Pincode must be exactly 4 characters',
  validationPincodeNumbersOnly: 'Pincode must be numbers only',
  validationPhoneRequired: 'Phone number is required',
  validationPhoneShorter: 'The phone number is too long',
  validationPhoneNumbersOnly: 'The phone must be numbers only',
  validationRequired: 'This field is required',
  validationShorter: 'This field must be shorter',
  equipmentCardWarningNotEnoughStock: 'There is not enough stock for this equipment',
  // consider: 'Please consider {0}, with availability: {1}',
  consider: 'Please consider {0}',
  orSiblings: 'or {0}',
  fieldRequired: 'Input is required',
  concept: 'concept',
  open: 'open',
  download: 'download',
  openPdf: 'Open PDF',
  downloadPdf: 'Download PDF',

  // Navigation
  allJobs: 'All Projects',
  allWorkOrders: 'All Workorders',
  allBranches: 'All Branches',
  allClients: 'All Clients',
  allEmployees: 'All Employees',
  allArchivedEmployees: 'Archived Employees',
  allEquipments: 'All Equipments',
  allUsers: 'All Users',
}
